import type { Pokedex, CartList } from "../types/interfaceCart";
type subTotalType = Pokedex[keyof Pick<Pokedex, "subTotal">];
interface CartState {
	count: number;
	cartRequest: boolean;
	cartFetching: boolean;
	cartList: CartList[];
	addCartData: any;
	cartData: Pokedex;
	qty: number;
	subTotal: subTotalType | unknown;
}
export interface GetCartPayload {
	isOnlyFetchCount?: number;
}
export const useCartStore = defineStore("cart", {
	state: (): CartState => {
		return {
			count: 0,
			cartList: [],
			cartRequest: false,
			cartFetching: false,
			cartData: {} as Pokedex,
			qty: 0,
			subTotal: {},
			addCartData: {}
		};
	},
	actions: {
		async getCart(payload?: GetCartPayload) {
			if (!payload?.isOnlyFetchCount && !this.cartRequest) {
				this.cartFetching = true;
			}
			const { data, error } = await useRequest.post("/api/cart/index", {
				data: {
					isOnlyFetchCount: payload?.isOnlyFetchCount || 0
				}
			});
			if (!payload?.isOnlyFetchCount) {
				this.cartFetching = false;
			}

			console.log("t2");
			if (data && data.value) {
				this.count = data?.value?.data?.count;
				if (data?.value?.data?.cartList?.length) {
					this.cartList = data?.value?.data?.cartList;
				}
				if (data?.value?.data && data?.value?.data?.cartList) {
					this.cartData = data.value.data;
				}

				if (!payload?.isOnlyFetchCount) {
					this.cartRequest = true;
				}
			}
		},
		setCartData(payload: Pokedex) {
			const { count, cartList, subTotal } = payload;
			this.cartData = payload;
			this.count = count;
			this.cartList = cartList;
			this.subTotal = subTotal;
		},
		setAddCartData(payload: Pokedex) {
			this.addCartData = payload;
		},
		setSubtotal(str: any) {
			this.cartData.checkedPriceInfo.subTotalWithChecked.subTotalFormat = str;
			this.cartData = JSON.parse(JSON.stringify(this.cartData));
		}
	}
});
