interface DeviceState {
	browser: string;
	browserZH: string;
	browserVersion: string;
	os: string;
	osVersion: string;
	device: string;
	engine: string;
	supportWebp: boolean;
	screenWidth: number;
	screenHeight: number;
	isChromeLighthouse: boolean;
}
export const useDeviceStore = defineStore("device", {
	state: (): DeviceState => {
		return {
			browser: "",
			browserZH: "",
			browserVersion: "",
			os: "",
			osVersion: "",
			device: "unknown",
			engine: "WebKit",
			supportWebp: false,
			screenWidth: 1920,
			screenHeight: 1080,
			isChromeLighthouse: false
		};
	},
	getters: {
		isMobile: state => {
			return state.screenWidth <= 768;
		},
		isMobile960: state => {
			return state.screenWidth <= 960;
		}
	},
	actions: {}
});
