import { simpleToTradition } from "chinese-simple2traditional";

const useLocaleLang = () => {
	const { $i18n } = useNuxtApp();
	const websiteStore = useWebsiteStore();
	return (p: string, options?: any) => {
		const t = $i18n.t;
		const tm = $i18n.tm;
		const rt = $i18n.rt;
		if (["hk", "tw", "mo"].includes(websiteStore.website)) {
			if (options && options.tm) {
				return JSON.parse(simpleToTradition(JSON.stringify(tm(p))));
			} else {
				return JSON.parse(simpleToTradition(JSON.stringify(t(p, options))));
			}
		} else {
			if (options && options.tm) {
				return Array.isArray(tm(p)) ? (tm(p) as any[]).map(i => rt(i)) : tm(p);
			} else {
				return t(p, options);
			}
		}
	};
};

export default useLocaleLang;
