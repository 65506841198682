import revive_payload_client_GEPee6qvOP from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_b4ce642990b715e314c5343d5b88a19a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_7zC6lzhNnZ from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_b4ce642990b715e314c5343d5b88a19a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_DTgmnvAGAM from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_b4ce642990b715e314c5343d5b88a19a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_ojBhJBo3q8 from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_b4ce642990b715e314c5343d5b88a19a/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_YEpr7WTCRJ from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_b4ce642990b715e314c5343d5b88a19a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_bVdNPrmgMB from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_b4ce642990b715e314c5343d5b88a19a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_LxivUcA2bJ from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.5_typescript@5.8.2_vue@3.4.30_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/.nuxt/components.plugin.mjs";
import prefetch_client_6K1c3SgcBT from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_b4ce642990b715e314c5343d5b88a19a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_dCfcLo3xEz from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.5_rollup@4.29.2_vue@3.4.30_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_D8CNgPGKAc from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_b4ce642990b715e314c5343d5b88a19a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _02_detectDevice_pxm8fCuh4v from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/plugins/02.detectDevice.ts";
import _03_lazyPlugin_Dd5b7eZc4K from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/plugins/03.lazyPlugin.ts";
import _04_errorMonitor_LOgjIHx1K2 from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/plugins/04.errorMonitor.ts";
import _05_loading_directive_BLzQLP4uua from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/plugins/05.loading.directive.ts";
import _06_liveChat_client_53hhWlm58s from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/plugins/06.liveChat.client.ts";
import _07_sentry_client_fpwQ0Adq5I from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/plugins/07.sentry.client.ts";
import _08_gtm_client_K93xH8zQNu from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/plugins/08.gtm.client.ts";
export default [
  revive_payload_client_GEPee6qvOP,
  unhead_7zC6lzhNnZ,
  router_DTgmnvAGAM,
  debug_ojBhJBo3q8,
  payload_client_YEpr7WTCRJ,
  check_outdated_build_client_bVdNPrmgMB,
  plugin_vue3_LxivUcA2bJ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_6K1c3SgcBT,
  i18n_dCfcLo3xEz,
  chunk_reload_client_D8CNgPGKAc,
  _02_detectDevice_pxm8fCuh4v,
  _03_lazyPlugin_Dd5b7eZc4K,
  _04_errorMonitor_LOgjIHx1K2,
  _05_loading_directive_BLzQLP4uua,
  _06_liveChat_client_53hhWlm58s,
  _07_sentry_client_fpwQ0Adq5I,
  _08_gtm_client_K93xH8zQNu
]