
// @ts-nocheck


export const localeCodes =  [
  "au",
  "de",
  "de-en",
  "en",
  "es",
  "mx",
  "fr",
  "it",
  "jp",
  "ru",
  "sg",
  "uk"
]

export const localeLoaders = {
  "au": [{ key: "../lang/au/common/header.ts", load: () => import("../lang/au/common/header.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_au_common_header_ts" */), cache: true },
{ key: "../lang/au/common/footer.ts", load: () => import("../lang/au/common/footer.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_au_common_footer_ts" */), cache: true },
{ key: "../lang/au/common/addCartPopup.ts", load: () => import("../lang/au/common/addCartPopup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_au_common_addCartPopup_ts" */), cache: true },
{ key: "../lang/au/common/ContactSales.ts", load: () => import("../lang/au/common/ContactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_au_common_ContactSales_ts" */), cache: true },
{ key: "../lang/au/common/error.ts", load: () => import("../lang/au/common/error.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_au_common_error_ts" */), cache: true },
{ key: "../lang/au/common/cookieTip.ts", load: () => import("../lang/au/common/cookieTip.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_au_common_cookieTip_ts" */), cache: true },
{ key: "../lang/au/common/telCode.ts", load: () => import("../lang/au/common/telCode.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_au_common_telCode_ts" */), cache: true },
{ key: "../lang/au/common/SelectCountry.ts", load: () => import("../lang/au/common/SelectCountry.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_au_common_SelectCountry_ts" */), cache: true },
{ key: "../lang/au/common/formValidate.ts", load: () => import("../lang/au/common/formValidate.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_au_common_formValidate_ts" */), cache: true },
{ key: "../lang/au/common/contactUs.ts", load: () => import("../lang/au/common/contactUs.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_au_common_contactUs_ts" */), cache: true },
{ key: "../lang/au/common/layout.ts", load: () => import("../lang/au/common/layout.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_au_common_layout_ts" */), cache: true },
{ key: "../lang/au/common/component.ts", load: () => import("../lang/au/common/component.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_au_common_component_ts" */), cache: true },
{ key: "../lang/au/common/popup.ts", load: () => import("../lang/au/common/popup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_au_common_popup_ts" */), cache: true },
{ key: "../lang/au/basic/home.ts", load: () => import("../lang/au/basic/home.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_au_basic_home_ts" */), cache: true }],
  "de": [{ key: "../lang/de/common/header.ts", load: () => import("../lang/de/common/header.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_common_header_ts" */), cache: true },
{ key: "../lang/de/common/footer.ts", load: () => import("../lang/de/common/footer.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_common_footer_ts" */), cache: true },
{ key: "../lang/de/common/addCartPopup.ts", load: () => import("../lang/de/common/addCartPopup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_common_addCartPopup_ts" */), cache: true },
{ key: "../lang/de/common/ContactSales.ts", load: () => import("../lang/de/common/ContactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_common_ContactSales_ts" */), cache: true },
{ key: "../lang/de/common/error.ts", load: () => import("../lang/de/common/error.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_common_error_ts" */), cache: true },
{ key: "../lang/de/common/cookieTip.ts", load: () => import("../lang/de/common/cookieTip.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_common_cookieTip_ts" */), cache: true },
{ key: "../lang/de/common/telCode.ts", load: () => import("../lang/de/common/telCode.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_common_telCode_ts" */), cache: true },
{ key: "../lang/de/common/SelectCountry.ts", load: () => import("../lang/de/common/SelectCountry.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_common_SelectCountry_ts" */), cache: true },
{ key: "../lang/de/common/formValidate.ts", load: () => import("../lang/de/common/formValidate.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_common_formValidate_ts" */), cache: true },
{ key: "../lang/de/common/contactUs.ts", load: () => import("../lang/de/common/contactUs.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_common_contactUs_ts" */), cache: true },
{ key: "../lang/de/common/layout.ts", load: () => import("../lang/de/common/layout.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_common_layout_ts" */), cache: true },
{ key: "../lang/de/common/component.ts", load: () => import("../lang/de/common/component.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_common_component_ts" */), cache: true },
{ key: "../lang/de/common/popup.ts", load: () => import("../lang/de/common/popup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_common_popup_ts" */), cache: true },
{ key: "../lang/de/basic/home.ts", load: () => import("../lang/de/basic/home.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_basic_home_ts" */), cache: true }],
  "de-en": [{ key: "../lang/de-en/common/header.ts", load: () => import("../lang/de-en/common/header.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_en_common_header_ts" */), cache: true },
{ key: "../lang/de-en/common/footer.ts", load: () => import("../lang/de-en/common/footer.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_en_common_footer_ts" */), cache: true },
{ key: "../lang/de-en/common/addCartPopup.ts", load: () => import("../lang/de-en/common/addCartPopup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_en_common_addCartPopup_ts" */), cache: true },
{ key: "../lang/de-en/common/ContactSales.ts", load: () => import("../lang/de-en/common/ContactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_en_common_ContactSales_ts" */), cache: true },
{ key: "../lang/de-en/common/error.ts", load: () => import("../lang/de-en/common/error.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_en_common_error_ts" */), cache: true },
{ key: "../lang/de-en/common/cookieTip.ts", load: () => import("../lang/de-en/common/cookieTip.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_en_common_cookieTip_ts" */), cache: true },
{ key: "../lang/de-en/common/telCode.ts", load: () => import("../lang/de-en/common/telCode.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_en_common_telCode_ts" */), cache: true },
{ key: "../lang/de-en/common/SelectCountry.ts", load: () => import("../lang/de-en/common/SelectCountry.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_en_common_SelectCountry_ts" */), cache: true },
{ key: "../lang/de-en/common/formValidate.ts", load: () => import("../lang/de-en/common/formValidate.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_en_common_formValidate_ts" */), cache: true },
{ key: "../lang/de-en/common/contactUs.ts", load: () => import("../lang/de-en/common/contactUs.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_en_common_contactUs_ts" */), cache: true },
{ key: "../lang/de-en/common/layout.ts", load: () => import("../lang/de-en/common/layout.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_en_common_layout_ts" */), cache: true },
{ key: "../lang/de-en/common/component.ts", load: () => import("../lang/de-en/common/component.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_en_common_component_ts" */), cache: true },
{ key: "../lang/de-en/common/popup.ts", load: () => import("../lang/de-en/common/popup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_en_common_popup_ts" */), cache: true },
{ key: "../lang/de-en/basic/home.ts", load: () => import("../lang/de-en/basic/home.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_de_en_basic_home_ts" */), cache: true }],
  "en": [{ key: "../lang/en/common/header.ts", load: () => import("../lang/en/common/header.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_en_common_header_ts" */), cache: true },
{ key: "../lang/en/common/footer.ts", load: () => import("../lang/en/common/footer.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_en_common_footer_ts" */), cache: true },
{ key: "../lang/en/common/addCartPopup.ts", load: () => import("../lang/en/common/addCartPopup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_en_common_addCartPopup_ts" */), cache: true },
{ key: "../lang/en/common/ContactSales.ts", load: () => import("../lang/en/common/ContactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_en_common_ContactSales_ts" */), cache: true },
{ key: "../lang/en/common/error.ts", load: () => import("../lang/en/common/error.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_en_common_error_ts" */), cache: true },
{ key: "../lang/en/common/cookieTip.ts", load: () => import("../lang/en/common/cookieTip.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_en_common_cookieTip_ts" */), cache: true },
{ key: "../lang/en/common/telCode.ts", load: () => import("../lang/en/common/telCode.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_en_common_telCode_ts" */), cache: true },
{ key: "../lang/en/common/SelectCountry.ts", load: () => import("../lang/en/common/SelectCountry.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_en_common_SelectCountry_ts" */), cache: true },
{ key: "../lang/en/common/formValidate.ts", load: () => import("../lang/en/common/formValidate.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_en_common_formValidate_ts" */), cache: true },
{ key: "../lang/en/common/contactUs.ts", load: () => import("../lang/en/common/contactUs.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_en_common_contactUs_ts" */), cache: true },
{ key: "../lang/en/common/layout.ts", load: () => import("../lang/en/common/layout.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_en_common_layout_ts" */), cache: true },
{ key: "../lang/en/common/component.ts", load: () => import("../lang/en/common/component.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_en_common_component_ts" */), cache: true },
{ key: "../lang/en/common/popup.ts", load: () => import("../lang/en/common/popup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_en_common_popup_ts" */), cache: true },
{ key: "../lang/en/basic/home.ts", load: () => import("../lang/en/basic/home.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_en_basic_home_ts" */), cache: true }],
  "es": [{ key: "../lang/es/common/header.ts", load: () => import("../lang/es/common/header.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_header_ts" */), cache: true },
{ key: "../lang/es/common/footer.ts", load: () => import("../lang/es/common/footer.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_footer_ts" */), cache: true },
{ key: "../lang/es/common/addCartPopup.ts", load: () => import("../lang/es/common/addCartPopup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_addCartPopup_ts" */), cache: true },
{ key: "../lang/es/common/ContactSales.ts", load: () => import("../lang/es/common/ContactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_ContactSales_ts" */), cache: true },
{ key: "../lang/es/common/error.ts", load: () => import("../lang/es/common/error.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_error_ts" */), cache: true },
{ key: "../lang/es/common/cookieTip.ts", load: () => import("../lang/es/common/cookieTip.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_cookieTip_ts" */), cache: true },
{ key: "../lang/es/common/telCode.ts", load: () => import("../lang/es/common/telCode.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_telCode_ts" */), cache: true },
{ key: "../lang/es/common/SelectCountry.ts", load: () => import("../lang/es/common/SelectCountry.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_SelectCountry_ts" */), cache: true },
{ key: "../lang/es/common/formValidate.ts", load: () => import("../lang/es/common/formValidate.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_formValidate_ts" */), cache: true },
{ key: "../lang/es/common/contactUs.ts", load: () => import("../lang/es/common/contactUs.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_contactUs_ts" */), cache: true },
{ key: "../lang/es/common/layout.ts", load: () => import("../lang/es/common/layout.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_layout_ts" */), cache: true },
{ key: "../lang/es/common/component.ts", load: () => import("../lang/es/common/component.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_component_ts" */), cache: true },
{ key: "../lang/es/common/popup.ts", load: () => import("../lang/es/common/popup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_popup_ts" */), cache: true },
{ key: "../lang/es/basic/home.ts", load: () => import("../lang/es/basic/home.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_basic_home_ts" */), cache: true }],
  "mx": [{ key: "../lang/es/common/header.ts", load: () => import("../lang/es/common/header.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_header_ts" */), cache: true },
{ key: "../lang/es/common/footer.ts", load: () => import("../lang/es/common/footer.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_footer_ts" */), cache: true },
{ key: "../lang/es/common/addCartPopup.ts", load: () => import("../lang/es/common/addCartPopup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_addCartPopup_ts" */), cache: true },
{ key: "../lang/es/common/ContactSales.ts", load: () => import("../lang/es/common/ContactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_ContactSales_ts" */), cache: true },
{ key: "../lang/es/common/error.ts", load: () => import("../lang/es/common/error.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_error_ts" */), cache: true },
{ key: "../lang/es/common/cookieTip.ts", load: () => import("../lang/es/common/cookieTip.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_cookieTip_ts" */), cache: true },
{ key: "../lang/es/common/telCode.ts", load: () => import("../lang/es/common/telCode.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_telCode_ts" */), cache: true },
{ key: "../lang/es/common/SelectCountry.ts", load: () => import("../lang/es/common/SelectCountry.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_SelectCountry_ts" */), cache: true },
{ key: "../lang/es/common/formValidate.ts", load: () => import("../lang/es/common/formValidate.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_formValidate_ts" */), cache: true },
{ key: "../lang/es/common/contactUs.ts", load: () => import("../lang/es/common/contactUs.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_contactUs_ts" */), cache: true },
{ key: "../lang/es/common/layout.ts", load: () => import("../lang/es/common/layout.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_layout_ts" */), cache: true },
{ key: "../lang/es/common/component.ts", load: () => import("../lang/es/common/component.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_component_ts" */), cache: true },
{ key: "../lang/es/common/popup.ts", load: () => import("../lang/es/common/popup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_common_popup_ts" */), cache: true },
{ key: "../lang/es/basic/home.ts", load: () => import("../lang/es/basic/home.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_es_basic_home_ts" */), cache: true }],
  "fr": [{ key: "../lang/fr/common/header.ts", load: () => import("../lang/fr/common/header.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_fr_common_header_ts" */), cache: true },
{ key: "../lang/fr/common/footer.ts", load: () => import("../lang/fr/common/footer.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_fr_common_footer_ts" */), cache: true },
{ key: "../lang/fr/common/addCartPopup.ts", load: () => import("../lang/fr/common/addCartPopup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_fr_common_addCartPopup_ts" */), cache: true },
{ key: "../lang/fr/common/ContactSales.ts", load: () => import("../lang/fr/common/ContactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_fr_common_ContactSales_ts" */), cache: true },
{ key: "../lang/fr/common/error.ts", load: () => import("../lang/fr/common/error.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_fr_common_error_ts" */), cache: true },
{ key: "../lang/fr/common/cookieTip.ts", load: () => import("../lang/fr/common/cookieTip.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_fr_common_cookieTip_ts" */), cache: true },
{ key: "../lang/fr/common/telCode.ts", load: () => import("../lang/fr/common/telCode.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_fr_common_telCode_ts" */), cache: true },
{ key: "../lang/fr/common/SelectCountry.ts", load: () => import("../lang/fr/common/SelectCountry.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_fr_common_SelectCountry_ts" */), cache: true },
{ key: "../lang/fr/common/formValidate.ts", load: () => import("../lang/fr/common/formValidate.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_fr_common_formValidate_ts" */), cache: true },
{ key: "../lang/fr/common/contactUs.ts", load: () => import("../lang/fr/common/contactUs.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_fr_common_contactUs_ts" */), cache: true },
{ key: "../lang/fr/common/layout.ts", load: () => import("../lang/fr/common/layout.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_fr_common_layout_ts" */), cache: true },
{ key: "../lang/fr/common/component.ts", load: () => import("../lang/fr/common/component.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_fr_common_component_ts" */), cache: true },
{ key: "../lang/fr/common/popup.ts", load: () => import("../lang/fr/common/popup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_fr_common_popup_ts" */), cache: true },
{ key: "../lang/fr/basic/home.ts", load: () => import("../lang/fr/basic/home.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_fr_basic_home_ts" */), cache: true }],
  "it": [{ key: "../lang/it/common/header.ts", load: () => import("../lang/it/common/header.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_it_common_header_ts" */), cache: true },
{ key: "../lang/it/common/footer.ts", load: () => import("../lang/it/common/footer.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_it_common_footer_ts" */), cache: true },
{ key: "../lang/it/common/addCartPopup.ts", load: () => import("../lang/it/common/addCartPopup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_it_common_addCartPopup_ts" */), cache: true },
{ key: "../lang/it/common/ContactSales.ts", load: () => import("../lang/it/common/ContactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_it_common_ContactSales_ts" */), cache: true },
{ key: "../lang/it/common/error.ts", load: () => import("../lang/it/common/error.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_it_common_error_ts" */), cache: true },
{ key: "../lang/it/common/cookieTip.ts", load: () => import("../lang/it/common/cookieTip.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_it_common_cookieTip_ts" */), cache: true },
{ key: "../lang/it/common/telCode.ts", load: () => import("../lang/it/common/telCode.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_it_common_telCode_ts" */), cache: true },
{ key: "../lang/it/common/SelectCountry.ts", load: () => import("../lang/it/common/SelectCountry.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_it_common_SelectCountry_ts" */), cache: true },
{ key: "../lang/it/common/formValidate.ts", load: () => import("../lang/it/common/formValidate.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_it_common_formValidate_ts" */), cache: true },
{ key: "../lang/it/common/contactUs.ts", load: () => import("../lang/it/common/contactUs.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_it_common_contactUs_ts" */), cache: true },
{ key: "../lang/it/common/layout.ts", load: () => import("../lang/it/common/layout.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_it_common_layout_ts" */), cache: true },
{ key: "../lang/it/common/component.ts", load: () => import("../lang/it/common/component.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_it_common_component_ts" */), cache: true },
{ key: "../lang/it/common/popup.ts", load: () => import("../lang/it/common/popup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_it_common_popup_ts" */), cache: true },
{ key: "../lang/it/basic/home.ts", load: () => import("../lang/it/basic/home.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_it_basic_home_ts" */), cache: true }],
  "jp": [{ key: "../lang/jp/common/header.ts", load: () => import("../lang/jp/common/header.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_jp_common_header_ts" */), cache: true },
{ key: "../lang/jp/common/footer.ts", load: () => import("../lang/jp/common/footer.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_jp_common_footer_ts" */), cache: true },
{ key: "../lang/jp/common/addCartPopup.ts", load: () => import("../lang/jp/common/addCartPopup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_jp_common_addCartPopup_ts" */), cache: true },
{ key: "../lang/jp/common/ContactSales.ts", load: () => import("../lang/jp/common/ContactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_jp_common_ContactSales_ts" */), cache: true },
{ key: "../lang/jp/common/error.ts", load: () => import("../lang/jp/common/error.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_jp_common_error_ts" */), cache: true },
{ key: "../lang/jp/common/cookieTip.ts", load: () => import("../lang/jp/common/cookieTip.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_jp_common_cookieTip_ts" */), cache: true },
{ key: "../lang/jp/common/telCode.ts", load: () => import("../lang/jp/common/telCode.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_jp_common_telCode_ts" */), cache: true },
{ key: "../lang/jp/common/SelectCountry.ts", load: () => import("../lang/jp/common/SelectCountry.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_jp_common_SelectCountry_ts" */), cache: true },
{ key: "../lang/jp/common/formValidate.ts", load: () => import("../lang/jp/common/formValidate.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_jp_common_formValidate_ts" */), cache: true },
{ key: "../lang/jp/common/contactUs.ts", load: () => import("../lang/jp/common/contactUs.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_jp_common_contactUs_ts" */), cache: true },
{ key: "../lang/jp/common/layout.ts", load: () => import("../lang/jp/common/layout.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_jp_common_layout_ts" */), cache: true },
{ key: "../lang/jp/common/component.ts", load: () => import("../lang/jp/common/component.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_jp_common_component_ts" */), cache: true },
{ key: "../lang/jp/common/popup.ts", load: () => import("../lang/jp/common/popup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_jp_common_popup_ts" */), cache: true },
{ key: "../lang/jp/basic/home.ts", load: () => import("../lang/jp/basic/home.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_jp_basic_home_ts" */), cache: true }],
  "ru": [{ key: "../lang/ru/common/header.ts", load: () => import("../lang/ru/common/header.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_ru_common_header_ts" */), cache: true },
{ key: "../lang/ru/common/footer.ts", load: () => import("../lang/ru/common/footer.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_ru_common_footer_ts" */), cache: true },
{ key: "../lang/ru/common/addCartPopup.ts", load: () => import("../lang/ru/common/addCartPopup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_ru_common_addCartPopup_ts" */), cache: true },
{ key: "../lang/ru/common/ContactSales.ts", load: () => import("../lang/ru/common/ContactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_ru_common_ContactSales_ts" */), cache: true },
{ key: "../lang/ru/common/error.ts", load: () => import("../lang/ru/common/error.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_ru_common_error_ts" */), cache: true },
{ key: "../lang/ru/common/cookieTip.ts", load: () => import("../lang/ru/common/cookieTip.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_ru_common_cookieTip_ts" */), cache: true },
{ key: "../lang/ru/common/telCode.ts", load: () => import("../lang/ru/common/telCode.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_ru_common_telCode_ts" */), cache: true },
{ key: "../lang/ru/common/SelectCountry.ts", load: () => import("../lang/ru/common/SelectCountry.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_ru_common_SelectCountry_ts" */), cache: true },
{ key: "../lang/ru/common/formValidate.ts", load: () => import("../lang/ru/common/formValidate.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_ru_common_formValidate_ts" */), cache: true },
{ key: "../lang/ru/common/contactUs.ts", load: () => import("../lang/ru/common/contactUs.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_ru_common_contactUs_ts" */), cache: true },
{ key: "../lang/ru/common/layout.ts", load: () => import("../lang/ru/common/layout.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_ru_common_layout_ts" */), cache: true },
{ key: "../lang/ru/common/component.ts", load: () => import("../lang/ru/common/component.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_ru_common_component_ts" */), cache: true },
{ key: "../lang/ru/common/popup.ts", load: () => import("../lang/ru/common/popup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_ru_common_popup_ts" */), cache: true },
{ key: "../lang/ru/basic/home.ts", load: () => import("../lang/ru/basic/home.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_ru_basic_home_ts" */), cache: true }],
  "sg": [{ key: "../lang/sg/common/header.ts", load: () => import("../lang/sg/common/header.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_sg_common_header_ts" */), cache: true },
{ key: "../lang/sg/common/footer.ts", load: () => import("../lang/sg/common/footer.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_sg_common_footer_ts" */), cache: true },
{ key: "../lang/sg/common/addCartPopup.ts", load: () => import("../lang/sg/common/addCartPopup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_sg_common_addCartPopup_ts" */), cache: true },
{ key: "../lang/sg/common/ContactSales.ts", load: () => import("../lang/sg/common/ContactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_sg_common_ContactSales_ts" */), cache: true },
{ key: "../lang/sg/common/error.ts", load: () => import("../lang/sg/common/error.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_sg_common_error_ts" */), cache: true },
{ key: "../lang/sg/common/cookieTip.ts", load: () => import("../lang/sg/common/cookieTip.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_sg_common_cookieTip_ts" */), cache: true },
{ key: "../lang/sg/common/telCode.ts", load: () => import("../lang/sg/common/telCode.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_sg_common_telCode_ts" */), cache: true },
{ key: "../lang/sg/common/SelectCountry.ts", load: () => import("../lang/sg/common/SelectCountry.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_sg_common_SelectCountry_ts" */), cache: true },
{ key: "../lang/sg/common/formValidate.ts", load: () => import("../lang/sg/common/formValidate.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_sg_common_formValidate_ts" */), cache: true },
{ key: "../lang/sg/common/contactUs.ts", load: () => import("../lang/sg/common/contactUs.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_sg_common_contactUs_ts" */), cache: true },
{ key: "../lang/sg/common/layout.ts", load: () => import("../lang/sg/common/layout.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_sg_common_layout_ts" */), cache: true },
{ key: "../lang/sg/common/component.ts", load: () => import("../lang/sg/common/component.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_sg_common_component_ts" */), cache: true },
{ key: "../lang/sg/common/popup.ts", load: () => import("../lang/sg/common/popup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_sg_common_popup_ts" */), cache: true },
{ key: "../lang/sg/basic/home.ts", load: () => import("../lang/sg/basic/home.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_sg_basic_home_ts" */), cache: true }],
  "uk": [{ key: "../lang/uk/common/header.ts", load: () => import("../lang/uk/common/header.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_uk_common_header_ts" */), cache: true },
{ key: "../lang/uk/common/footer.ts", load: () => import("../lang/uk/common/footer.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_uk_common_footer_ts" */), cache: true },
{ key: "../lang/uk/common/addCartPopup.ts", load: () => import("../lang/uk/common/addCartPopup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_uk_common_addCartPopup_ts" */), cache: true },
{ key: "../lang/uk/common/ContactSales.ts", load: () => import("../lang/uk/common/ContactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_uk_common_ContactSales_ts" */), cache: true },
{ key: "../lang/uk/common/error.ts", load: () => import("../lang/uk/common/error.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_uk_common_error_ts" */), cache: true },
{ key: "../lang/uk/common/cookieTip.ts", load: () => import("../lang/uk/common/cookieTip.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_uk_common_cookieTip_ts" */), cache: true },
{ key: "../lang/uk/common/telCode.ts", load: () => import("../lang/uk/common/telCode.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_uk_common_telCode_ts" */), cache: true },
{ key: "../lang/uk/common/SelectCountry.ts", load: () => import("../lang/uk/common/SelectCountry.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_uk_common_SelectCountry_ts" */), cache: true },
{ key: "../lang/uk/common/formValidate.ts", load: () => import("../lang/uk/common/formValidate.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_uk_common_formValidate_ts" */), cache: true },
{ key: "../lang/uk/common/contactUs.ts", load: () => import("../lang/uk/common/contactUs.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_uk_common_contactUs_ts" */), cache: true },
{ key: "../lang/uk/common/layout.ts", load: () => import("../lang/uk/common/layout.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_uk_common_layout_ts" */), cache: true },
{ key: "../lang/uk/common/component.ts", load: () => import("../lang/uk/common/component.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_uk_common_component_ts" */), cache: true },
{ key: "../lang/uk/common/popup.ts", load: () => import("../lang/uk/common/popup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_uk_common_popup_ts" */), cache: true },
{ key: "../lang/uk/basic/home.ts", load: () => import("../lang/uk/basic/home.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_homepage_prod_lang_uk_basic_home_ts" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "au",
      "files": [
        "lang/au/common/header.ts",
        "lang/au/common/footer.ts",
        "lang/au/common/addCartPopup.ts",
        "lang/au/common/ContactSales.ts",
        "lang/au/common/error.ts",
        "lang/au/common/cookieTip.ts",
        "lang/au/common/telCode.ts",
        "lang/au/common/SelectCountry.ts",
        "lang/au/common/formValidate.ts",
        "lang/au/common/contactUs.ts",
        "lang/au/common/layout.ts",
        "lang/au/common/component.ts",
        "lang/au/common/popup.ts",
        "lang/au/basic/home.ts"
      ]
    },
    {
      "code": "de",
      "files": [
        "lang/de/common/header.ts",
        "lang/de/common/footer.ts",
        "lang/de/common/addCartPopup.ts",
        "lang/de/common/ContactSales.ts",
        "lang/de/common/error.ts",
        "lang/de/common/cookieTip.ts",
        "lang/de/common/telCode.ts",
        "lang/de/common/SelectCountry.ts",
        "lang/de/common/formValidate.ts",
        "lang/de/common/contactUs.ts",
        "lang/de/common/layout.ts",
        "lang/de/common/component.ts",
        "lang/de/common/popup.ts",
        "lang/de/basic/home.ts"
      ]
    },
    {
      "code": "de-en",
      "files": [
        "lang/de-en/common/header.ts",
        "lang/de-en/common/footer.ts",
        "lang/de-en/common/addCartPopup.ts",
        "lang/de-en/common/ContactSales.ts",
        "lang/de-en/common/error.ts",
        "lang/de-en/common/cookieTip.ts",
        "lang/de-en/common/telCode.ts",
        "lang/de-en/common/SelectCountry.ts",
        "lang/de-en/common/formValidate.ts",
        "lang/de-en/common/contactUs.ts",
        "lang/de-en/common/layout.ts",
        "lang/de-en/common/component.ts",
        "lang/de-en/common/popup.ts",
        "lang/de-en/basic/home.ts"
      ]
    },
    {
      "code": "en",
      "files": [
        "lang/en/common/header.ts",
        "lang/en/common/footer.ts",
        "lang/en/common/addCartPopup.ts",
        "lang/en/common/ContactSales.ts",
        "lang/en/common/error.ts",
        "lang/en/common/cookieTip.ts",
        "lang/en/common/telCode.ts",
        "lang/en/common/SelectCountry.ts",
        "lang/en/common/formValidate.ts",
        "lang/en/common/contactUs.ts",
        "lang/en/common/layout.ts",
        "lang/en/common/component.ts",
        "lang/en/common/popup.ts",
        "lang/en/basic/home.ts"
      ]
    },
    {
      "code": "es",
      "files": [
        "lang/es/common/header.ts",
        "lang/es/common/footer.ts",
        "lang/es/common/addCartPopup.ts",
        "lang/es/common/ContactSales.ts",
        "lang/es/common/error.ts",
        "lang/es/common/cookieTip.ts",
        "lang/es/common/telCode.ts",
        "lang/es/common/SelectCountry.ts",
        "lang/es/common/formValidate.ts",
        "lang/es/common/contactUs.ts",
        "lang/es/common/layout.ts",
        "lang/es/common/component.ts",
        "lang/es/common/popup.ts",
        "lang/es/basic/home.ts"
      ]
    },
    {
      "code": "mx",
      "files": [
        "lang/es/common/header.ts",
        "lang/es/common/footer.ts",
        "lang/es/common/addCartPopup.ts",
        "lang/es/common/ContactSales.ts",
        "lang/es/common/error.ts",
        "lang/es/common/cookieTip.ts",
        "lang/es/common/telCode.ts",
        "lang/es/common/SelectCountry.ts",
        "lang/es/common/formValidate.ts",
        "lang/es/common/contactUs.ts",
        "lang/es/common/layout.ts",
        "lang/es/common/component.ts",
        "lang/es/common/popup.ts",
        "lang/es/basic/home.ts"
      ]
    },
    {
      "code": "fr",
      "files": [
        "lang/fr/common/header.ts",
        "lang/fr/common/footer.ts",
        "lang/fr/common/addCartPopup.ts",
        "lang/fr/common/ContactSales.ts",
        "lang/fr/common/error.ts",
        "lang/fr/common/cookieTip.ts",
        "lang/fr/common/telCode.ts",
        "lang/fr/common/SelectCountry.ts",
        "lang/fr/common/formValidate.ts",
        "lang/fr/common/contactUs.ts",
        "lang/fr/common/layout.ts",
        "lang/fr/common/component.ts",
        "lang/fr/common/popup.ts",
        "lang/fr/basic/home.ts"
      ]
    },
    {
      "code": "it",
      "files": [
        "lang/it/common/header.ts",
        "lang/it/common/footer.ts",
        "lang/it/common/addCartPopup.ts",
        "lang/it/common/ContactSales.ts",
        "lang/it/common/error.ts",
        "lang/it/common/cookieTip.ts",
        "lang/it/common/telCode.ts",
        "lang/it/common/SelectCountry.ts",
        "lang/it/common/formValidate.ts",
        "lang/it/common/contactUs.ts",
        "lang/it/common/layout.ts",
        "lang/it/common/component.ts",
        "lang/it/common/popup.ts",
        "lang/it/basic/home.ts"
      ]
    },
    {
      "code": "jp",
      "files": [
        "lang/jp/common/header.ts",
        "lang/jp/common/footer.ts",
        "lang/jp/common/addCartPopup.ts",
        "lang/jp/common/ContactSales.ts",
        "lang/jp/common/error.ts",
        "lang/jp/common/cookieTip.ts",
        "lang/jp/common/telCode.ts",
        "lang/jp/common/SelectCountry.ts",
        "lang/jp/common/formValidate.ts",
        "lang/jp/common/contactUs.ts",
        "lang/jp/common/layout.ts",
        "lang/jp/common/component.ts",
        "lang/jp/common/popup.ts",
        "lang/jp/basic/home.ts"
      ]
    },
    {
      "code": "ru",
      "files": [
        "lang/ru/common/header.ts",
        "lang/ru/common/footer.ts",
        "lang/ru/common/addCartPopup.ts",
        "lang/ru/common/ContactSales.ts",
        "lang/ru/common/error.ts",
        "lang/ru/common/cookieTip.ts",
        "lang/ru/common/telCode.ts",
        "lang/ru/common/SelectCountry.ts",
        "lang/ru/common/formValidate.ts",
        "lang/ru/common/contactUs.ts",
        "lang/ru/common/layout.ts",
        "lang/ru/common/component.ts",
        "lang/ru/common/popup.ts",
        "lang/ru/basic/home.ts"
      ]
    },
    {
      "code": "sg",
      "files": [
        "lang/sg/common/header.ts",
        "lang/sg/common/footer.ts",
        "lang/sg/common/addCartPopup.ts",
        "lang/sg/common/ContactSales.ts",
        "lang/sg/common/error.ts",
        "lang/sg/common/cookieTip.ts",
        "lang/sg/common/telCode.ts",
        "lang/sg/common/SelectCountry.ts",
        "lang/sg/common/formValidate.ts",
        "lang/sg/common/contactUs.ts",
        "lang/sg/common/layout.ts",
        "lang/sg/common/component.ts",
        "lang/sg/common/popup.ts",
        "lang/sg/basic/home.ts"
      ]
    },
    {
      "code": "uk",
      "files": [
        "lang/uk/common/header.ts",
        "lang/uk/common/footer.ts",
        "lang/uk/common/addCartPopup.ts",
        "lang/uk/common/ContactSales.ts",
        "lang/uk/common/error.ts",
        "lang/uk/common/cookieTip.ts",
        "lang/uk/common/telCode.ts",
        "lang/uk/common/SelectCountry.ts",
        "lang/uk/common/formValidate.ts",
        "lang/uk/common/contactUs.ts",
        "lang/uk/common/layout.ts",
        "lang/uk/common/component.ts",
        "lang/uk/common/popup.ts",
        "lang/uk/basic/home.ts"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "lang",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "au",
    "files": [
      {
        "path": "lang/au/common/header.ts"
      },
      {
        "path": "lang/au/common/footer.ts"
      },
      {
        "path": "lang/au/common/addCartPopup.ts"
      },
      {
        "path": "lang/au/common/ContactSales.ts"
      },
      {
        "path": "lang/au/common/error.ts"
      },
      {
        "path": "lang/au/common/cookieTip.ts"
      },
      {
        "path": "lang/au/common/telCode.ts"
      },
      {
        "path": "lang/au/common/SelectCountry.ts"
      },
      {
        "path": "lang/au/common/formValidate.ts"
      },
      {
        "path": "lang/au/common/contactUs.ts"
      },
      {
        "path": "lang/au/common/layout.ts"
      },
      {
        "path": "lang/au/common/component.ts"
      },
      {
        "path": "lang/au/common/popup.ts"
      },
      {
        "path": "lang/au/basic/home.ts"
      }
    ]
  },
  {
    "code": "de",
    "files": [
      {
        "path": "lang/de/common/header.ts"
      },
      {
        "path": "lang/de/common/footer.ts"
      },
      {
        "path": "lang/de/common/addCartPopup.ts"
      },
      {
        "path": "lang/de/common/ContactSales.ts"
      },
      {
        "path": "lang/de/common/error.ts"
      },
      {
        "path": "lang/de/common/cookieTip.ts"
      },
      {
        "path": "lang/de/common/telCode.ts"
      },
      {
        "path": "lang/de/common/SelectCountry.ts"
      },
      {
        "path": "lang/de/common/formValidate.ts"
      },
      {
        "path": "lang/de/common/contactUs.ts"
      },
      {
        "path": "lang/de/common/layout.ts"
      },
      {
        "path": "lang/de/common/component.ts"
      },
      {
        "path": "lang/de/common/popup.ts"
      },
      {
        "path": "lang/de/basic/home.ts"
      }
    ]
  },
  {
    "code": "de-en",
    "files": [
      {
        "path": "lang/de-en/common/header.ts"
      },
      {
        "path": "lang/de-en/common/footer.ts"
      },
      {
        "path": "lang/de-en/common/addCartPopup.ts"
      },
      {
        "path": "lang/de-en/common/ContactSales.ts"
      },
      {
        "path": "lang/de-en/common/error.ts"
      },
      {
        "path": "lang/de-en/common/cookieTip.ts"
      },
      {
        "path": "lang/de-en/common/telCode.ts"
      },
      {
        "path": "lang/de-en/common/SelectCountry.ts"
      },
      {
        "path": "lang/de-en/common/formValidate.ts"
      },
      {
        "path": "lang/de-en/common/contactUs.ts"
      },
      {
        "path": "lang/de-en/common/layout.ts"
      },
      {
        "path": "lang/de-en/common/component.ts"
      },
      {
        "path": "lang/de-en/common/popup.ts"
      },
      {
        "path": "lang/de-en/basic/home.ts"
      }
    ]
  },
  {
    "code": "en",
    "files": [
      {
        "path": "lang/en/common/header.ts"
      },
      {
        "path": "lang/en/common/footer.ts"
      },
      {
        "path": "lang/en/common/addCartPopup.ts"
      },
      {
        "path": "lang/en/common/ContactSales.ts"
      },
      {
        "path": "lang/en/common/error.ts"
      },
      {
        "path": "lang/en/common/cookieTip.ts"
      },
      {
        "path": "lang/en/common/telCode.ts"
      },
      {
        "path": "lang/en/common/SelectCountry.ts"
      },
      {
        "path": "lang/en/common/formValidate.ts"
      },
      {
        "path": "lang/en/common/contactUs.ts"
      },
      {
        "path": "lang/en/common/layout.ts"
      },
      {
        "path": "lang/en/common/component.ts"
      },
      {
        "path": "lang/en/common/popup.ts"
      },
      {
        "path": "lang/en/basic/home.ts"
      }
    ]
  },
  {
    "code": "es",
    "files": [
      {
        "path": "lang/es/common/header.ts"
      },
      {
        "path": "lang/es/common/footer.ts"
      },
      {
        "path": "lang/es/common/addCartPopup.ts"
      },
      {
        "path": "lang/es/common/ContactSales.ts"
      },
      {
        "path": "lang/es/common/error.ts"
      },
      {
        "path": "lang/es/common/cookieTip.ts"
      },
      {
        "path": "lang/es/common/telCode.ts"
      },
      {
        "path": "lang/es/common/SelectCountry.ts"
      },
      {
        "path": "lang/es/common/formValidate.ts"
      },
      {
        "path": "lang/es/common/contactUs.ts"
      },
      {
        "path": "lang/es/common/layout.ts"
      },
      {
        "path": "lang/es/common/component.ts"
      },
      {
        "path": "lang/es/common/popup.ts"
      },
      {
        "path": "lang/es/basic/home.ts"
      }
    ]
  },
  {
    "code": "mx",
    "files": [
      {
        "path": "lang/es/common/header.ts"
      },
      {
        "path": "lang/es/common/footer.ts"
      },
      {
        "path": "lang/es/common/addCartPopup.ts"
      },
      {
        "path": "lang/es/common/ContactSales.ts"
      },
      {
        "path": "lang/es/common/error.ts"
      },
      {
        "path": "lang/es/common/cookieTip.ts"
      },
      {
        "path": "lang/es/common/telCode.ts"
      },
      {
        "path": "lang/es/common/SelectCountry.ts"
      },
      {
        "path": "lang/es/common/formValidate.ts"
      },
      {
        "path": "lang/es/common/contactUs.ts"
      },
      {
        "path": "lang/es/common/layout.ts"
      },
      {
        "path": "lang/es/common/component.ts"
      },
      {
        "path": "lang/es/common/popup.ts"
      },
      {
        "path": "lang/es/basic/home.ts"
      }
    ]
  },
  {
    "code": "fr",
    "files": [
      {
        "path": "lang/fr/common/header.ts"
      },
      {
        "path": "lang/fr/common/footer.ts"
      },
      {
        "path": "lang/fr/common/addCartPopup.ts"
      },
      {
        "path": "lang/fr/common/ContactSales.ts"
      },
      {
        "path": "lang/fr/common/error.ts"
      },
      {
        "path": "lang/fr/common/cookieTip.ts"
      },
      {
        "path": "lang/fr/common/telCode.ts"
      },
      {
        "path": "lang/fr/common/SelectCountry.ts"
      },
      {
        "path": "lang/fr/common/formValidate.ts"
      },
      {
        "path": "lang/fr/common/contactUs.ts"
      },
      {
        "path": "lang/fr/common/layout.ts"
      },
      {
        "path": "lang/fr/common/component.ts"
      },
      {
        "path": "lang/fr/common/popup.ts"
      },
      {
        "path": "lang/fr/basic/home.ts"
      }
    ]
  },
  {
    "code": "it",
    "files": [
      {
        "path": "lang/it/common/header.ts"
      },
      {
        "path": "lang/it/common/footer.ts"
      },
      {
        "path": "lang/it/common/addCartPopup.ts"
      },
      {
        "path": "lang/it/common/ContactSales.ts"
      },
      {
        "path": "lang/it/common/error.ts"
      },
      {
        "path": "lang/it/common/cookieTip.ts"
      },
      {
        "path": "lang/it/common/telCode.ts"
      },
      {
        "path": "lang/it/common/SelectCountry.ts"
      },
      {
        "path": "lang/it/common/formValidate.ts"
      },
      {
        "path": "lang/it/common/contactUs.ts"
      },
      {
        "path": "lang/it/common/layout.ts"
      },
      {
        "path": "lang/it/common/component.ts"
      },
      {
        "path": "lang/it/common/popup.ts"
      },
      {
        "path": "lang/it/basic/home.ts"
      }
    ]
  },
  {
    "code": "jp",
    "files": [
      {
        "path": "lang/jp/common/header.ts"
      },
      {
        "path": "lang/jp/common/footer.ts"
      },
      {
        "path": "lang/jp/common/addCartPopup.ts"
      },
      {
        "path": "lang/jp/common/ContactSales.ts"
      },
      {
        "path": "lang/jp/common/error.ts"
      },
      {
        "path": "lang/jp/common/cookieTip.ts"
      },
      {
        "path": "lang/jp/common/telCode.ts"
      },
      {
        "path": "lang/jp/common/SelectCountry.ts"
      },
      {
        "path": "lang/jp/common/formValidate.ts"
      },
      {
        "path": "lang/jp/common/contactUs.ts"
      },
      {
        "path": "lang/jp/common/layout.ts"
      },
      {
        "path": "lang/jp/common/component.ts"
      },
      {
        "path": "lang/jp/common/popup.ts"
      },
      {
        "path": "lang/jp/basic/home.ts"
      }
    ]
  },
  {
    "code": "ru",
    "files": [
      {
        "path": "lang/ru/common/header.ts"
      },
      {
        "path": "lang/ru/common/footer.ts"
      },
      {
        "path": "lang/ru/common/addCartPopup.ts"
      },
      {
        "path": "lang/ru/common/ContactSales.ts"
      },
      {
        "path": "lang/ru/common/error.ts"
      },
      {
        "path": "lang/ru/common/cookieTip.ts"
      },
      {
        "path": "lang/ru/common/telCode.ts"
      },
      {
        "path": "lang/ru/common/SelectCountry.ts"
      },
      {
        "path": "lang/ru/common/formValidate.ts"
      },
      {
        "path": "lang/ru/common/contactUs.ts"
      },
      {
        "path": "lang/ru/common/layout.ts"
      },
      {
        "path": "lang/ru/common/component.ts"
      },
      {
        "path": "lang/ru/common/popup.ts"
      },
      {
        "path": "lang/ru/basic/home.ts"
      }
    ]
  },
  {
    "code": "sg",
    "files": [
      {
        "path": "lang/sg/common/header.ts"
      },
      {
        "path": "lang/sg/common/footer.ts"
      },
      {
        "path": "lang/sg/common/addCartPopup.ts"
      },
      {
        "path": "lang/sg/common/ContactSales.ts"
      },
      {
        "path": "lang/sg/common/error.ts"
      },
      {
        "path": "lang/sg/common/cookieTip.ts"
      },
      {
        "path": "lang/sg/common/telCode.ts"
      },
      {
        "path": "lang/sg/common/SelectCountry.ts"
      },
      {
        "path": "lang/sg/common/formValidate.ts"
      },
      {
        "path": "lang/sg/common/contactUs.ts"
      },
      {
        "path": "lang/sg/common/layout.ts"
      },
      {
        "path": "lang/sg/common/component.ts"
      },
      {
        "path": "lang/sg/common/popup.ts"
      },
      {
        "path": "lang/sg/basic/home.ts"
      }
    ]
  },
  {
    "code": "uk",
    "files": [
      {
        "path": "lang/uk/common/header.ts"
      },
      {
        "path": "lang/uk/common/footer.ts"
      },
      {
        "path": "lang/uk/common/addCartPopup.ts"
      },
      {
        "path": "lang/uk/common/ContactSales.ts"
      },
      {
        "path": "lang/uk/common/error.ts"
      },
      {
        "path": "lang/uk/common/cookieTip.ts"
      },
      {
        "path": "lang/uk/common/telCode.ts"
      },
      {
        "path": "lang/uk/common/SelectCountry.ts"
      },
      {
        "path": "lang/uk/common/formValidate.ts"
      },
      {
        "path": "lang/uk/common/contactUs.ts"
      },
      {
        "path": "lang/uk/common/layout.ts"
      },
      {
        "path": "lang/uk/common/component.ts"
      },
      {
        "path": "lang/uk/common/popup.ts"
      },
      {
        "path": "lang/uk/basic/home.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
