export function useHeaderHeight() {
	const headerStore = useHeaderStore();
	if (process.client) {
		nextTick(() => {
			const ph = document.querySelector<HTMLElement>(".fs_header_wrap");
			const mh = document.querySelector<HTMLElement>(".m_fs_header_wrap");
			const mth = document.querySelector<HTMLElement>(".m_fs_header_wrap .m_header_top");

			let m = (mh as HTMLElement)?.clientHeight || 0;
			if (headerStore.m_header_ctn_fixed) {
				m -= (mth as HTMLElement).clientHeight;
			}
			headerStore.$patch(state => {
				state.pc_header_height = ph?.clientHeight || 0;
				state.m_header_height = m;
			});
		});
	}
}
