// import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin(async nuxtApp => {
	const { vueApp } = nuxtApp;
	const router = useRouter();
	const runtimeConfig = useRuntimeConfig();
	const websiteStore = useWebsiteStore();
	const userStore = useUserStore();
	const deviceStore = useDeviceStore();

	const { website, iso_code, country_name, language, currency, symbol, isCn, isJp, isJpEn, isRussia, clienIp } = storeToRefs(websiteStore);
	const { isLogin, userInfo } = storeToRefs(userStore);

	const { browser, browserVersion, os, osVersion, screenWidth, screenHeight, isChromeLighthouse } = storeToRefs(deviceStore);
	if (runtimeConfig.public.NODE_ENV === "production" && ["PROD", "PROD_INDEX", "PROD_CN"].includes(runtimeConfig.public.VITE_NUXT_ENV) && !isChromeLighthouse.value) {
		const Sentry = await import("@sentry/vue").then(module => module.default || module);
		Sentry.init({
			app: [vueApp],
			dsn: "https://28b351e3ee18bd76aca626f3d67db3a3@sentry.fs.com/4",
			environment: runtimeConfig.public.VITE_NUXT_ENV,
			dist: runtimeConfig.public.VITE_NUXT_ENV,
			integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
			tracesSampleRate: 1.0,
			replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1.0,
			beforeSend(event, hint) {
				if (event.exception) {
					console.log("==================");
					console.log(event);
					console.log(hint);
					console.error(`[Exeption handled by Sentry]: (${hint.originalException})`, { event, hint });
					console.log(Sentry);
					console.log("==================");
				}
				return event;
			}
		});

		Sentry.setUser({
			ip_address: clienIp.value ? clienIp.value : ``,
			"Login Status": isLogin.value ? `已登录` : `未登录`,
			"Customers Email Address": userInfo.value?.customers_email_address ? userInfo.value?.customers_email_address : ``,
			"Device Screen Size": `宽${screenWidth.value}  高${screenHeight.value}`,
			"Website Info": `country：${country_name.value}   language：${language.value}   currency：${currency.value}`
		});
		vueApp.mixin(
			Sentry.createTracingMixins({
				trackComponents: true,
				timeout: 2000,
				hooks: ["activate", "mount", "update"]
			})
		);
		Sentry.attachErrorHandler(vueApp, {
			logErrors: false,
			attachProps: true,
			trackComponents: true,
			timeout: 2000,
			hooks: ["activate", "mount", "update"]
		});

		return {
			provide: {
				sentrySetContext: Sentry.setContext,
				sentrySetUser: Sentry.setUser,
				sentrySetTag: Sentry.setTag,
				sentryAddBreadcrumb: Sentry.addBreadcrumb,
				sentryCaptureException: Sentry.captureException
			}
		};
	}
});
