export default defineNuxtPlugin(nuxtApp => {
	const reportError = useReportError();
	const runtimeConfig = useRuntimeConfig();
	if (runtimeConfig.public.NODE_ENV === "production" && ["RELEASE", "RELEASE_INDEX", "PROD", "PROD_INDEX"].includes(runtimeConfig.public.VITE_NUXT_ENV)) {
		nuxtApp.vueApp.config.errorHandler = (error: any, instance, info) => {
			console.error(error);
			if (error) {
				reportError(error?.stack?.toString() ?? error?.toString());
			}
		};
		if (process.server) {
			nuxtApp.hook("vue:error", (error: any) => {
				console.error(error);
				if (error) {
					reportError(error?.stack?.toString() ?? error?.toString());
				}
			});
		}
	}
});
