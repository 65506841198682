import { watch } from "vue";
import aes from "@/utils/aes";
import { communityGaPramas } from "@/utils/utils";

export default defineNuxtRouteMiddleware((to, from) => {
	const headers = useRequestHeaders();
	const referer = headers.referer || "";
	const runtimeConfig = useRuntimeConfig();
	const userStore = useUserStore();
	const websiteStore = useWebsiteStore();
	const { isLogin, isFetch, gaLoginString, userInfo } = storeToRefs(userStore);
	const { iso_code, language, currency, website } = storeToRefs(websiteStore);
	const fsPvid = useCookies("_fs_pvid");
	const gaStore = useGaStore();
	// console.log("22_222");
	// console.log(useRequestHeaders());
	const meatStore = useMeatStore();

	const { domain, title } = storeToRefs(meatStore);

	const { preUrl, pageLocation } = storeToRefs(gaStore);
	gaStore.$patch(state => {
		state.preUrl = referer;
	});
	const routeName = to?.name?.substring(0, to?.name?.lastIndexOf("___"));

	const websiteLanguage = iso_code.value + "_" + language.value + "_" + currency.value;
	let ecommPagetype = "";
	if (routeName === "home") {
		ecommPagetype = "home";
	} else if (routeName === "search_result") {
		ecommPagetype = "searchresults";
	} else if (routeName === "category") {
		ecommPagetype = "category";
	} else {
		ecommPagetype = "other";
	}

	if (runtimeConfig?.public?.VITE_NUXT_ENV && process.client) {
		const ga_community_str = communityGaPramas(to);
		console.log("pv_pv_pv_222");
		console.log(window.dataLayer);
		console.log(to);
		if (window?.dataLayer) {
			window.dataLayer.push(function (this: { reset: () => void }) {
				if (typeof this.reset === "function") {
					this.reset();
				}
			});
			console.log("pv_pv_pv");
			console.log(isLogin.value);
			console.log(isFetch.value);
			console.log(gaLoginString.value);

			const obj: any = {
				event: "PageView",
				// loginStatus: `${loginState}_${gaLoginString}`,
				// userId: userId,
				websiteLanguage,
				fsPvid: fsPvid.value,
				ecommPagetype,
				page_location: pageLocation.value,
				page_path: to.fullPath,
				page_title: title.value,
				"c_site&c_ctype&c_from&c_cat&c_rel": ga_community_str,
				page_url: `${domain.value}${to.fullPath}`,
				page_referrer: preUrl.value,
				urlSite: website.value === "en" ? "us" : website.value
			};

			console.log("Referer_222:", referer);

			if (isFetch.value) {
				const loginState = isLogin.value ? "Login" : "Logout";
				const userId =
					isLogin.value && userInfo?.value ? aes.encrypt(`${userInfo?.value?.customers_level}${userInfo?.value?.customers_id}`, `_-yu_xuan_3507-_`, `fs_com_phone2016`) : "";

				obj.loginStatus = `${loginState}_${gaLoginString.value}`;

				obj.userId = userId;
				console.log("pvpvpvpv__111");
				console.log(obj);
				window.dataLayer.push(obj);
			} else {
				watch(
					() => isFetch.value,
					newValue => {
						const loginState = isLogin.value ? "Login" : "Logout";

						const userId =
							isLogin.value && userInfo?.value ? aes.encrypt(`${userInfo?.value?.customers_level}${userInfo?.value?.customers_id}`, `_-yu_xuan_3507-_`, `fs_com_phone2016`) : "";
						obj.loginStatus = `${loginState}_${gaLoginString.value}`;

						obj.userId = userId;
						console.log("pvpvpvpv__222");
						console.log(obj);
						console.log("Referer_333:", referer);
						window.dataLayer.push(obj);
					}
				);
			}
		}
	}
});
